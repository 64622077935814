import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, BASE_URL_PREBID } from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
// import AddSurvay from "./ViewSurvay";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
// import AddDocument from "./SurveyDocumentUpload";

import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReceivedAmount from "../../pages/account/Main/ReceivedAmount";
import { formattedDateLong } from "../Criticalissue/Date";
import Invoice from "../../pages/account/Main/Invoice";

import { DeleteInvoice } from "./Invoice";
import UpdateProjectDetaiils from "../Modals/UpdateProjectDetails";


const DeleteProjectDocuments = ({
    id,
    getDocuments

}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic


        try {
            console.log("Delete Expense Detail Api");
            let res = await axios.delete(
                `${BASE_URL}/project/documentupdate/${id.id}/`
            );

            if (res.status === 200) {
                await getDocuments();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                        fill="#F72B50"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are You Sure, Your want to Delete this File

                    <div className="button-models">

                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button model-button-cancel"
                        >
                            Delete
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

const ProjectDetailsBilling = ({ i, totall, status }) => {
    let { id } = useParams();

    const [allWorkStagesStats, setAllWorkstagesStatus] = useState([]);



    const [filteredData, setFilteredData] = useState([]);

    const [buffer, setBuffering] = useState(true); //buffering logic
    const [inputState] = useState({});
    const [imageData, setimageData] = useState({
        project: id,
        document_name: "",
        file: "",
    });

    const [allEmployeeData, setAllEmployeeData] = useState([]);

    useEffect(() => {
        getEmployeeDetails();
    }, []);

    const getEmployeeDetails = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanysdep/${sessionStorage.getItem("departmentid")}/`
            );
            setAllEmployeeData(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    const getJobappdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL_PREBID}/project/invoicebyproject/${id}/`);

            const ress = await axios.get(
                `${BASE_URL_PREBID}/project/pendingforInvoiceforaccount/${id}/`
            );


            setAllWorkstagesStatus(ress.data)



            setFilteredData(res.data);

        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }

        } finally {
            setBuffering(false);// End Buffering
        }
    };



    useEffect(() => {
        // handleSearch();
        getJobappdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const navigate = useNavigate()

    const currentUrl = window.location.href;


    const urlParts = currentUrl.split('/');



    const baseUrl2 = "/" + urlParts[3] + `/invoiceSlip`;
    const baseUrl3 = "/" + urlParts[3] + `/performainvoiceslip`;

    const invoicePage = (pr_code) => {
        navigate(baseUrl2 + `/${pr_code}/`)
    }
    const performainvoicePage = (pr_code) => {
        navigate(baseUrl3 + `/${pr_code}/`)
    }


    const handleBack = (e) => {
        navigate(-1);
    };



    // ! **********Expense Detail by Date Get Api Start ***********

    const [project, setProject] = useState([]);
    const getproject = async () => {
        try {
            const ress = await axios.get(
                `${BASE_URL_PREBID}/project/projectupdate/${id}/`
            );
            setProject(ress.data.project_data);
        } catch (err) {

        }
    };


    const [docs, setDocuments] = useState([]);
    const getDocuments = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/project/documentbyproject/${id}/`
            );
            setDocuments(res.data);
        } catch (err) {

        }
    };

    useEffect(() => {
        getDocuments();
        getproject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // ! **********Expense Detail Add Api Start ***********

    // ! Pic Start ===============================

    const [showAddPic, setShowAddPic] = useState(false);
    const handleClosePic = () => setShowAddPic(false);
    const handleShowPic = () => setShowAddPic(true);




    const [errors, setpicErrors] = useState({});
    const [picinputState, setpicInputState] = useState({});

    const validatePicForm = () => {
        const newpicErrors = {};
        const picRequiredFields = [
            "project",
            "document_name",
            "file",
        ];

        picRequiredFields.forEach((field) => {
            if (!imageData[field]) {
                newpicErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setpicErrors(newpicErrors);

        return Object.keys(newpicErrors).length === 0;

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setpicInputState({
            ...picinputState,
            [name]: value.trim() ? "green" : "",
        });
        setimageData({
            ...imageData,
            [name]: value,
            // 'pic': e.target.files[0]
        });
    };
    const [fileName, setFileName] = useState("");

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            setimageData({ ...imageData, [name]: file, });
            setFileName(file.name);
            // Additional logic for handling the file can be added here
        } else {
            // setimageData({...imageData,[name]: file,});
            setFileName("");
        }
    };

    const [setLoading] = useState(false); //loading logic

    const handlePicPost = async (e, getDocuments) => {
        e.preventDefault();

        if (validatePicForm()) {

            const formDataa = new FormData();
            formDataa.append("project", imageData.project);
            formDataa.append("document_name", imageData.document_name);

            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const now = new Date();
            const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
            const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = imageData.file;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, { type: originalFile.type });


            formDataa.append("file", customFile);
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {

                let res = await axios.post(`${BASE_URL}/project/document/`, formDataa)

                if (res.status === 200) {
                    await getDocuments()
                    handleClosePic();

                    // Clear formDataa
                    formDataa.delete("project");
                    formDataa.delete("document_name");
                    formDataa.delete("file");

                    // Reset imageData (assuming imageData is a state managed with useState)
                    setimageData({
                        project: '',
                        document_name: '',
                        file: null
                    });
                    setFileName("");

                    // setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                // alert(err);
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };





    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <Dashboardnavbarcopy url="Projects" name={"Project Details"} />


            <div className="table-css-white-backgroundd">

                <div className="flex-row">
                    <div className="personal-detail" style={{ width: "100%" }} >
                        <div className="project-details-button">
                            <div className="project-long">

                                <h3 className="jd-heading-main font-weight600  font-size-heading">Project name  :   {project.project_short_name}</h3>

                            </div>
                            <div>
                                <UpdateProjectDetaiils
                                    id={project.pr_code}
                                    modalData={project}
                                    allEmployeeData={allEmployeeData}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="jd-heading-bottom-bold-personal"></div>
                            <div className="jd-heading-bottom-light"></div>
                        </div>
                        <div className="personal-detail-table1">
                            <table>  <tr>
                                <td className="personal-detail-1 font-weight500 font-size-text">Project Name</td>
                                <th className="personal-detail-2 font-weight600 font-size-text" style={{ paddingLeft: "70px" }}>
                                    {project.project_name ? project.project_name : '------'}
                                </th>
                            </tr></table>
                        </div>
                        <div className="personal-detail-table" >
                            <div>
                                <table>

                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Pr. Code</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.prebid_prcode ? project.prebid_prcode : '------'}
                                        </th>
                                    </tr>

                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Client Name</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.client_name ? project.client_name : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Location</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.location ? project.location : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Length</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.length ? project.length : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Work Order Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.wo_date ? project.wo_date : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Target Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.target_date ? project.target_date : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Due Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.due_date ? project.due_date : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Length</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.length ? project.length : '------'}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Portal Submission Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.portal_submission_date ? project.portal_submission_date : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Cost</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.cost ? project.cost : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Remark</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.remark ? project.remark : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Link</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.link ? project.link : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">L1 Position</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.l1_position ? project.l1_position : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Our Position</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.our_position ? project.our_position : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Opening Date</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.opening_date ? project.opening_date : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Status</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.status ? project.status : '------'}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <table>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Length</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.length ? project.length : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Confirm By</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.confirm_by ? project.confirm_by : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Contract Mode</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.contract_mode ? project.contract_mode : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Confirmation Mode</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.confirmation_mode ? project.confirmation_mode : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">GST Amount</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.gst_amount ? project.gst_amount : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Work Order Amount</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.woamount ? project.woamount : '------'}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="personal-detail-1 font-weight500 font-size-text">Without GST Amount</td>
                                        <th className="personal-detail-2 font-weight500 font-size-text">
                                            {project.without_gst_amount ? project.without_gst_amount : '------'}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div >
                </div>
                <br></br>
                <div >
                    {/* <div className="flex-row">
              <h6 className="main-acts">Document</h6>
            </div> */}

                    <div className="jd-heading-outer">
                       <div className="document-details-button">
                        <div className="document-long ">
                            <h2 className="jd-heading-main font-weight600  font-size-heading">Document</h2>
                        </div>
                        <div>
                            <button
                                title="Add Documents"
                                onClick={handleShowPic}
                                className="upload-svg "
                            >
                                Add Document {" "}


                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" enable-background="new 0 0 32 32" viewBox="0 0 32 32" fill='white' id="add"><path d="M2.4375,17.9375h11.625v11.625c0,1.06854,0.86896,1.9375,1.9375,1.9375s1.9375-0.86896,1.9375-1.9375v-11.625h11.625c1.06854,0,1.9375-0.86896,1.9375-1.9375s-0.86896-1.9375-1.9375-1.9375h-11.625V2.4375C17.9375,1.36896,17.06854,0.5,16,0.5s-1.9375,0.86896-1.9375,1.9375v11.625H2.4375C1.36896,14.0625,0.5,14.93146,0.5,16S1.36896,17.9375,2.4375,17.9375z"></path></svg>

                            </button>
                        </div>
                       </div>
                        <div>
                            <div className="jd-heading-bottom-bold"></div>
                            <div className="jd-heading-bottom-light"></div>
                        </div>
                    </div>

                    <table className="project-details-table">
                        <thead>
                            <tr className="custom-table-head-trr">
                                <th className="align-left font-size-text font-weight600">S. No.</th>
                                <th className="align-center font-size-text font-weight600">Document Name</th>
                                <th className="align-center font-size-text font-weight600">Document File</th>
                                <th className="align-right font-size-text font-weight600">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {docs.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td font-weight500 font-size-label">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">
                                            {i.document_name}
                                        </td>
                                        <td className="align-center">
                                            {i.file ? (
                                                // <a href={i.document_file} target="blank">
                                                //   View
                                                // </a>

                                                <a href={i.file} download={`${i.file}`} target="blank">
                                                    <img
                                                        className="performance-reward-img"
                                                        src={`${i.file}`}
                                                        alt="Assignerpic"
                                                    />
                                                </a>
                                            ) : (
                                                "Document Not Attached"
                                            )}
                                        </td>

                                        <td className="align-right font-size-text font-weight400 width-5vw ">
                                            <DeleteProjectDocuments
                                                id={i}
                                                getDocuments={getDocuments}
                                            />
                                        </td>
                                    </tr>


                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <hr className="line" />
                    {showAddPic === true ? (
                        <div className="expense-req ">

                            <div className="flex-column field-cont-div">
                                <label
                                    htmlFor="document_name"
                                    className="form-labels announce-date font-weight500  font-size-heading"
                                >
                                    File Name
                                </label>
                                <input
                                    type="text"
                                    id="document_name"
                                    name="document_name"
                                    placeholder="Document Name"
                                    onChange={handleInputChange}
                                    value={imageData.document_name}
                                    className={`form-modal-input ${errors.document_name
                                        ? "error"
                                        : picinputState.document_name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.document_name && (
                                    <span className="error-message font-size-text ">{errors.document_name}</span>
                                )}
                            </div>

                            <div className="flex-column field-cont-div">
                                <label
                                    htmlFor=""
                                    className="form-labels announce-date font-weight500  font-size-heading"
                                >
                                    Upload File
                                </label>
                                <label
                                    htmlFor="file"
                                    className={`align-center form-inputss custom-file-upload ${errors.file
                                        ? "error"
                                        : inputState.file
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <svg
                                        className="pdf-input"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                            stroke="#707070"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </label>
                                <input
                                    id="file"
                                    type="file"
                                    name="file"
                                    onChange={handleFileChange}
                                    className="file-inputs align-center"

                                    style={{ display: "none", position: "relative" }}
                                />
                                {fileName && <p className="file-name align-center">{fileName}</p>}

                                {errors.file && (
                                    <span className="error-message font-size-text ">{errors.file}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label
                                    htmlFor=""
                                    className="announce-date font-weight400 form-inputss"
                                ></label>
                                <div className="flex-row action-div" >
                                    <button className="" onClick={handleClosePic}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                stroke="red"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M15 9L9 15"
                                                stroke="red"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M9 9L15 15"
                                                stroke="red"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        className="form-inputs-submit width-5vw"
                                        onClick={(e) => handlePicPost(e, getDocuments)}
                                    // disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (

                        <div>
                        </div>
                    )}
                </div>

                {/********************************************************* incoices ************************************************************/}
                <div className="attendance-box">
                    <div className="jd-heading-outer">
                       <div className="invoices-details-button">
                        <div className="invoice-long ">
                            <h2 className="jd-heading-main font-weight600  font-size-heading">Invoices</h2>
                        </div>
                        <div>
                        <div>{allWorkStagesStats >= 0 ? "" : <Invoice id={id} />}</div>
                        </div>
                       </div>
                        <div>
                            <div className="jd-heading-bottom-bold"></div>
                            <div className="jd-heading-bottom-light"></div>
                        </div>
                    </div>

                    <div className="datagrid-container">
                        <div className="invoice-table">
                            
                                <div className="invoice-table2 ">
                                <table className="project-details-table" >
                                
                                        <thead>
                                            <tr className="custom-table-head-invoices">
                                                <th className="align-center font-size-text font-weight600">Sr no.</th>
                                                <th className="align-center font-size-text font-weight600">Invoice No.</th>
                                                <th className="align-center font-size-text font-weight600">Project Name</th>
                                                <th className="align-center font-size-text font-weight600">Client Name</th>
                                                <th className="align-center font-size-text font-weight600">Total Amount</th>
                                                <th className="align-center font-size-text font-weight600">GST</th>
                                                <th className="align-center font-size-text font-weight600">Amount without GST</th>
                                                <th className="align-center font-size-text font-weight600">Billing Amount</th>
                                                {/* <th className="align-center font-size-text font-weight600">Balance to bill</th>  */}
                                                <th className="align-center font-size-text font-weight600">Billing Percentage</th>
                                                <th className="align-center font-size-text font-weight600">Received Date</th>
                                                <th className="align-center font-size-text font-weight600">Received Amount</th>
                                                <th className="align-center font-size-text font-weight600">View Invoice</th>
                                                <th className="align-center font-size-text font-weight600">View Perfoma Invoice</th>
                                                <th className="align-center font-size-text font-weight600">Add Received Amt.</th>
                                                <th className="align-center font-size-text font-weight600">Delete Invoice</th>
                                            </tr>
                                        </thead>
                            
                               
                                    {buffer ? <div className="spinner"></div> : //buffering logic

                                      
                                   

                                                <tbody >

                                                    {filteredData
                                                        .sort((a, b) => a.client_name.localeCompare(b.client_name, 'en', { ignorePunctuation: true }))

                                                        .map((i, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr className="tr-border-bottom">
                                                                    <td colSpan="8"></td>
                                                                </tr>
                                                                <tr className="custom-table-head-td">
                                                                    <td className="align-center font-size-text font-weight400">{index + 1}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.invoice_number}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.project_name} </td>
                                                                    <td className="align-center font-size-text font-weight400">{i.client_name}<br/>{i.contact_number}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.project_woamount}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.project_gst_amount}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.project_without_gst_amount}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.billamount}</td>
                                                                    {/* <td className="align-center font-size-text font-weight400">{i.woamount - i.received_amount}</td> */}
                                                                    <td className="align-center font-size-text font-weight400">{i.bill_percentage}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.rec_date != null ? formattedDateLong(i.rec_date) : ""}</td>
                                                                    <td className="align-center font-size-text font-weight400">{i.amount_recived}</td>
                                                                    <td className="align-center font-size-text font-weight400" style={{ display: "flex" }}>
                                                                        {/* <button className="allproject-dashboard  font-size-heading font-weight500" onClick={(e) => invoicePage(i.id)}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="40"
                                                                    height="20"
                                                                    viewBox="0 0 30 30"
                                                                    fill="none">

                                                                    <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                                    <path
                                                                        d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                        fill="#2576BC"
                                                                    />

                                                                </svg>
                                                            </button> */}
                                                                        <button className="models-button model-add" onClick={(e) => invoicePage(i.id)}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="30"
                                                                                height="30"
                                                                                viewBox="0 0 30 30"
                                                                                fill="none"
                                                                            >
                                                                                <circle
                                                                                    cx="15"
                                                                                    cy="15"
                                                                                    r="15"
                                                                                    fill="#F6F7F9"
                                                                                ></circle>
                                                                                <path
                                                                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                                    fill="#2576BC"
                                                                                ></path>
                                                                            </svg>
                                                                        </button>

                                                                    </td>
                                                                    <td className="align-center">
                                                                        <button className="models-button model-add" onClick={(e) => performainvoicePage(i.id)}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="30"
                                                                                height="30"
                                                                                viewBox="0 0 30 30"
                                                                                fill="none"
                                                                            >
                                                                                <circle
                                                                                    cx="15"
                                                                                    cy="15"
                                                                                    r="15"
                                                                                    fill="#F6F7F9"
                                                                                ></circle>
                                                                                <path
                                                                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                                                    fill="#2576BC"
                                                                                ></path>
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <ReceivedAmount id={i.id} maxlimitreceive={i.billamount} />
                                                                    </td>
                                                                    <td>
                                                                        <DeleteInvoice id={i.id} getInvoiceData={getJobappdata} />
                                                                    </td>



                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                </tbody>
 
                                            
                                     
                                    }
                                </table>
                                </div>
                          
                        </div>
                    </div>
                </div>

                {/* Back To Project  */}
                <div className="button-models">
                    <button className="model-button" onClick={handleBack}>
                        Back To Project
                    </button>
                </div>
            </div>
        </>
    );
};


export default ProjectDetailsBilling;

