import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import axios from 'axios'
import { BASE_URL_PREBID } from '../../config/axios';
import { useParams } from 'react-router-dom';
import { formattedDateLong } from '../Criticalissue/Date'
import numberToWords from 'number-to-words';
import { toast } from 'react-toastify';
import { Modal, ToastContainer } from 'react-bootstrap';
const DeleteInvoice = ({
  id,
  getInvoiceData
}) => {
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false); //loading logic
  // console.log(id)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    setLoading(true); // loading logic
    try {
      console.log("Delete Expense Detail Api");
      let res = await axios.delete(
        `${BASE_URL_PREBID}/project/invoicedelete/${id}/`
      );

      if (res.status === 200) {
        await getInvoiceData("null");
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {

      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure, Your want to Delete
        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <br />
          {id.prebid_prcode} - {id.project_name}

          <div className="button-models">

            <button
              onClick={handleFormSubmitDelete}
              className="model-button model-button-cancel"
            >
              Delete
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};
const SalarySlip = React.forwardRef((props, ref) => {

  const [invoiceData, setInvoiceData] = useState({
    "project": {},
    "client": {},
    "invoice": {},
  })
  const projectData = [
    {
      name: "Max",
      food: 100.0,
      fill: "#fff",
    },
  ]

  let { id } = useParams();
  const [match, setMatch] = useState(false);
  const [word, setWord] = useState("");
  const invoiceGSTIN = "06AAHCC0747L1ZK";


  const getInvoiceData = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/invoicedetails/${id}/`);
      setInvoiceData(res.data)

      const firstTwoCharactersRGSTIN = res.data?.client?.gstin_number?.substring(0, 2);
      const firstTwoCharactersIGSTIN = invoiceGSTIN.substring(0, 2);
      setWord(numberToWords.toWords(res.data.invoice.billamount))
      // const gstinMatch = firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN;
      if (firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN) {
        setMatch(true);
      }
    } catch (err) {
    }
  };
  const InvoiceData = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL_PREBID}/project/invoicedetails/${id}/`);
      setInvoiceData(res.data)

      const firstTwoCharactersRGSTIN = res.data?.client?.gstin_number?.substring(0, 2);
      const firstTwoCharactersIGSTIN = invoiceGSTIN.substring(0, 2);
      setWord(numberToWords.toWords(res.data.invoice.billamount))
      // const gstinMatch = firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN;
      if (firstTwoCharactersRGSTIN === firstTwoCharactersIGSTIN) {
        setMatch(true);
      }
    } catch (err) {
    }
  };


  useEffect(() => {
    getInvoiceData(id);



  }, []);


  return (
    <div className='salaryslip' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />

            {/* <img src={Logo} alt='logo' className='salaryslip-logo' /> */}
            <div className='salaryslip-header-left-text'>
              TAX INVOICE
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>

        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second slip-column-2heads'>


          <div className='salaryslip-heading-black'>
            Recipient Detail
          </div>
          <div className=' salaryslip-heading-black'>
            Invoice Detail
          </div>
        </div>
        <div className='salaryslip-header-second'>


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black font-weight500'>Name</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 font-weight700'>{invoiceData.client.name}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Address</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.address}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Email</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.email}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Contact No.</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1 '>{invoiceData.client.contact_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>POS</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.pos}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>GSTIN</td>
                <td className='salaryslip-heading-black1 salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr>



              <br />

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

          <div className='salaryslip-vertical-division2'></div>
          {/* <div className='salaryslip-vertical-division'></div> */}


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='salaryslip-heading-black font-weight500'>Invoice no.</td>
                <td className='salaryslip-heading-black1 font-weight700'>{invoiceData.invoice.invoice_number}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Invoice Date</td>
                <td className='salaryslip-heading-black1'>{formattedDateLong(invoiceData.invoice.billing_date)}</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>CIPL Project no.</td>
                <td className='salaryslip-heading-black1'>{invoiceData.project.prebid_prcode}</td>
              </tr>
              {/* <tr>
                <td className='salaryslip-heading-black font-weight500'>Running Invoice</td>
                <td className='salaryslip-heading-black1'>{invoiceData.client.gstin_number}</td>
              </tr> */}
              <tr>
                <td className='salaryslip-heading-black font-weight500'>PAN</td>
                <td className='salaryslip-heading-black1'>AAHCC0747L</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>Account No.</td>
                <td className='salaryslip-heading-black1'>50200026004051</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>IFSC</td>
                <td className='salaryslip-heading-black1'>HDFC0009090</td>
              </tr>
              <tr>
                <td className='salaryslip-heading-black font-weight500'>GSTIN</td>
                <td className='salaryslip-heading-black1'>{invoiceGSTIN}</td>
              </tr>



              {/* <br /> */}

              {/* <tr>
                    <td colSpan={65} className='salaryslip-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

        </div>
        <div className='salaryslip-hr'></div>
        {/* <br /> */}
        <tr className="salaryslip-division  ">
          <td ><th>Name of Work:</th>
            {invoiceData.project.project_name}
            <th>- Pre Tender Engineering Services</th></td>
        </tr>



        <div className='salaryslip-body'>
          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                <th className="expense-table-td expenseslip-row-data2">Project particulars</th>
                <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                <th className="expense-table-td expenseslip-row-data4">Amount</th>

              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr className='expense-table-row'>
                  <td className='expense-table-td expenseslip-row-data1'>{1}</td>
                  <td className='expenseslip-row-data2'>{invoiceData.invoice.work_stages}</td>
                  <td className='expense-table-td expenseslip-row-data3'>{invoiceData.project.without_gst_amount}</td>
                  <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.bill_percentage}%</td>
                  <td className='expense-table-td-r expenseslip-row-data4 align-center'>{invoiceData.invoice.wihoutgst_amount}</td>
                </tr>
                <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

                {/* <tr className='expense-table-row'>
                        <td className='expense-table-td expenseslip-row-data1'>{index + 1}</td>
                        <td className='expenseslip-row-data2'>kjh oasdhfskdjfhkj f fsd fsd fsdfsdf sdf dsf sdfsdf sdf sd fd </td>
                        <td className='expense-table-td expenseslip-row-data3'>28000</td>
                        <td className='expense-table-td expenseslip-row-data4'>28000</td>
                        <td className='expense-table-td expenseslip-row-data3'>200</td>
                      </tr>
                      <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}

              </React.Fragment>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr> */}
              {/* <td ></td> */}
              {/* <td className='expenseslip-row-data2'><b>Payment Stage -</b> Advance 25%</td> */}
              {/* </tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr>
                <td ></td>
                <td className='expenseslip-row-data2'><b></b> </td>
                <td className="expense-table-td-r expenseslip-row-data3"> </td>
                <td className="expense-table-td-r expenseslip-row-data4"></td>
                <td className="expense-table-td-r expenseslip-row-data4"> </td>
              </tr> */}
              <br />
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>

              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}
              {/* <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr> */}


            </tbody>
          </table>
          {/* <div className="salaryslip-body"> */}
          <table className='salary-table'>

            {/* <thead>
                  <tr className='expenseslip-head'>
                    <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                    <th className="expense-table-td expenseslip-row-data5">Project particulars</th>
                    <th className="expense-table-td expenseslip-row-data6">Amount</th>
                    <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                    <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                    <th className="expense-table-td expenseslip-row-data3">Amount</th>
                  </tr>
                </thead> */}

            <tbody>
              <tr><td colSpan={5} className='salaryslip-horizontal-division'></td></tr>


              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount Before Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>{invoiceData.invoice.wihoutgst_amount}</b></td>
              </tr>
              <tr className='expense-table-row'>
                <td className='expense-table-td expenseslip-head-data expenseslip-row-data1'></td>
                <td className='expenseslip-row-data5'><b>Work Order Summary -</b> </td>
                <td className='expense-table-td expenseslip-row-data6'><b>Amount</b> </td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>IGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>18%</td>
                <td className="expense-table-td expenseslip-row-data4">{match ? "---" : invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Value of Work Order</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.project.without_gst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>SGST  </b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing Up to Previous Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.total_billing_up_to_previous < 0 ? "-" : invoiceData.total_billing_up_to_previous}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>CGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>{!match ? "---" : (invoiceData.invoice.gst_amount / 2)}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing in Current Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>{invoiceData.invoice.wihoutgst_amount}</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>Total Tax</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td expenseslip-row-data4'>{invoiceData.invoice.gst_amount}</td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Balance to Bill</b></td>
                <td className='expense-table-td expenseslip-row-data6'><b>

                  {/* {invoiceData.balance_to_bill} */}
                  {invoiceData.project.without_gst_amount - (invoiceData.total_billing_up_to_previous < 0 ? 0 : invoiceData.total_billing_up_to_previous) - invoiceData.invoice.wihoutgst_amount}

                </b></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount After Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>{invoiceData.invoice.billamount}</b></td>
              </tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>

              {/* <br /> */}
            </tbody>


          </table>


          <tr className='expense-table-row'>
            <td className="expense-table-td expenseslip-row-data1"> </td>
            <td className=' '><b style={{ textTransform: "capitalize" }}>{word} Only</b></td>
          </tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>
          <tr><td colSpan={6} className='salaryslip-horizontal-division'></td></tr>


        </div>
        {/* <div className='salaryslip-hr'></div> */}
        <div className='salaryslip-header-third'>

          <div>
            <th>Terms and Conditions:-</th>
            {/* <td>Terms and Conditions:-</td> */}
            <h6 className='TnC'>1. Invoice is True and fair </h6>
            <h6 className='TnC'>2. All disputes belong to Haryana Jurisdiction </h6>
            <h6 className='TnC'>3. Payment Shall be made witin 5 Days of </h6>
            <h6 className='TnC'>4. We are Registered MSME Unit Vide No.- UDYAM-DL-11-0010217 </h6>
            <h6 className='TnC'><b>5. This is Software generated Invoice no signature required</b></h6>

          </div>
          {/* <div className='salaryslip-bottom-signature'>
            <th>For CivilMantra Infracon Pvt Ltd</th>
            <img src={"Signature"} alt='signature' className='slaryslip-bg-signature' />
            <th>AUTHORISED SIGNATURE</th>
          </div> */}
        </div>
      </div>
      <div className='slaryslip-bg'>
      </div>
    </div>
  );
});

const InvoicePage = () => {
  const ref = useRef();

  return (
    <div>
      {/* Your other components or content */}
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <SalarySlip ref={ref} />
    </div>
  );
};

export {InvoicePage ,DeleteInvoice};