import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import { IMAGE_URL } from "../config/axios";

const handleErrorToast = (err, loadingToastId = null) => {
    console.log("err");
    console.log(err);

    if (loadingToastId) {
        toast.dismiss(loadingToastId);
    }

    if (err.response) {
        const errorData = err.response.data;

        // Check if there's a generic message field
        if (errorData.message) {
            toast.error(`Error: ${errorData.message}`);
        } else if (errorData.detail && errorData.messages) {
            const detail = errorData.detail;
            const code = errorData.code;
            const messages = errorData.messages;

            toast.error(`Error: ${detail} (Code: ${code})`);

            messages.forEach((messageObj) => {
                const tokenClass = messageObj.token_class || "Unknown Token";
                const tokenType = messageObj.token_type || "Unknown Type";
                const message = messageObj.message || "Unknown Error";

                toast.error(
                    `Token Class: ${tokenClass}, Type: ${tokenType} - ${message}`
                );
            });
        } else if (typeof errorData.error === "string") {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
            Object.entries(errorData).forEach(([field, messages]) => {
                messages.forEach((message) => toast.error(`"${field}": ${message}`));
            });
        } else if (typeof errorData === "string" && errorData.includes("<html")) {
            // Handling HTML error page
            const parser = new DOMParser();
            const doc = parser.parseFromString(errorData, "text/html");
            const pageTitle =
                doc.querySelector("title")?.innerText || "Unknown Error";

            toast.error(`Error: ${pageTitle}`);
        } else {
            toast.error("Error: Failed to Process!");
        }
    } else {
        toast.error("Error processing your request.");
    }
};

const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
];
const getMonthName = (monthNumber) => {
    const month = monthOptions.find((m) => m.id.toString() === monthNumber);
    return month ? month.name : "";
};

const formatCurrencyIndian = (amount) => {
    if (amount === null || amount === undefined) return "-";
    const hasDecimals = amount % 1 !== 0;

    return new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 21,
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
    }).format(amount);
};

const formatRoundoffCrores = (amount) => {
    if (amount === null || amount === undefined) return "-";

    if (amount < 10000000) {
        return new Intl.NumberFormat("en-IN", {
            maximumSignificantDigits: 21,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    }
    const amountInCr = amount / 10000000;
    return `${amountInCr.toFixed(2)} Cr`;
};

const formatRoundoff = (amount) => {
    if (amount === null || amount === undefined) return "-";

    // Function to format the number with Indian number system
    const formatIndianStyle = (num) => {
        let numStr = num.toString();
        let lastThreeDigits = numStr.slice(-3);
        let otherDigits = numStr.slice(0, -3);

        if (otherDigits) {
            lastThreeDigits = "," + lastThreeDigits;
        }

        // Split other digits into groups of two and add commas
        const formattedOtherDigits = otherDigits.replace(
            /\B(?=(\d{2})+(?!\d))/g,
            ","
        );

        return formattedOtherDigits + lastThreeDigits;
    };

    return formatIndianStyle(amount);
};

const formatToIndianCurrency = (amount) =>
    new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 21,
        style: "decimal",
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    }).format(amount);

const sortProjects = (data, codeKey) => {
    const splitCodeParts = (code) => {
        if (!code || typeof code !== "string") {
            return { projectType: "", numericPart: 0, company: "" };
        }

        // Split the code into parts based on "/"
        const parts = code.trim().split("/");

        // Treat everything before the numeric part as the project type
        const projectType = parts.slice(0, -2).join("/"); // Everything before the numeric part
        // const numericPart = parts[parts.length - 2] ? parseInt(parts[parts.length - 2], 10) : 0; // Second last part (numeric part)
        const numericPart = parts[parts.length - 2]
            ? parts[parts.length - 2].padStart(3, "0")
            : "000";
        const company = parts[parts.length - 1] || ""; // Last part as company (e.g., "CIPL", "SP")

        return { projectType, numericPart, company };
    };

    return data.sort((a, b) => {
        const aParts = splitCodeParts(a[codeKey]);
        const bParts = splitCodeParts(b[codeKey]);

        // Compare project type (everything before the numeric part)
        if (aParts.projectType !== bParts.projectType) {
            return aParts.projectType.localeCompare(bParts.projectType);
        }

        // Compare company name (e.g., "CIPL" vs "SP")
        if (aParts.company !== bParts.company) {
            return aParts.company.localeCompare(bParts.company);
        }

        // Compare numeric part (e.g., 1 vs 2)
        // return aParts.numericPart - bParts.numericPart;
        // return Number(aParts.numericPart) - Number(bParts.numericPart);
        // Compare numeric part with validation for NaN
        const numA = Number(aParts.numericPart);
        const numB = Number(bParts.numericPart);

        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Compare numerically if both are valid numbers
        } else {
            // If not valid numbers, fallback to localeCompare
            // return aParts.numericPart.toString().localeCompare(bParts.numericPart.toString());
            return aParts.numericPart.localeCompare(bParts.numericPart); // Compare as strings if not valid
        }
    });
};

const calculateTotal = (data, getFieldValue) => {
    return data.reduce((total, item) => {
        return total + Number(getFieldValue(item));
    }, 0);
};

const customSortProjects = (data, codeKey) => {
    const splitCodeParts = (code) => {
        if (!code || typeof code !== "string") {
            return { firstPart: "", numericPart: 0, alphaPart: "" };
        }

        // Split the code into two parts: before and after the last "/"
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/"); // Everything before the last "/"
        const lastPart = parts.pop(); // The last part after the "/"

        // Match numeric and alphabetic parts from the last part (e.g., "001CM" -> 001 and CM)
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/); // Adjust regex to prioritize alphabetic part
        if (match) {
            return {
                firstPart, // Part before the last "/"
                numericPart: match[1] ? parseInt(match[1], 10) : 0, // Numeric part (if present)
                alphaPart: match[2] || "", // Alphabetic part
            };
        }

        return { firstPart, numericPart: 0, alphaPart: "" };
    };

    return data.sort((a, b) => {
        const aParts = splitCodeParts(a[codeKey]);
        const bParts = splitCodeParts(b[codeKey]);

        // Compare the first part (e.g., "AE/IE" vs "OM")
        if (aParts.firstPart !== bParts.firstPart) {
            return aParts.firstPart.localeCompare(bParts.firstPart);
        }

        // If the first parts are the same, compare the alphabetic part (e.g., "CM" vs "SP")
        if (aParts.alphaPart !== bParts.alphaPart) {
            return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }

        // If alphabetic parts are the same, compare the numeric part (e.g., "001" vs "002")
        return aParts.numericPart - bParts.numericPart;
    });
};

const ascSortByKey = (data, key) => {
    const regex = /([A-Za-z]+)|(\d+)|(\W+)/g;

    // Function to access a deeply nested object property using dot notation
    const getNestedValue = (obj, key) => {
        return key.split(".").reduce((o, i) => (o ? o[i] : undefined), obj);
    };

    // Custom sort function for object comparison
    const sortFunction = (a, b) => {
        // Get the values to be compared from the key (handle nested properties)
        const aValue = getNestedValue(a, key);
        const bValue = getNestedValue(b, key);

        if (!aValue || !bValue) return 0; // Return 0 if either value is undefined

        // Split the strings into alphabetic, numeric, and special character parts
        const aParts = aValue.match(regex);
        const bParts = bValue.match(regex);

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aPart = aParts[i];
            const bPart = bParts[i];

            // Check if both parts are numeric
            if (!isNaN(aPart) && !isNaN(bPart)) {
                const numA = Number(aPart);
                const numB = Number(bPart);
                if (numA !== numB) {
                    return numA - numB; // Compare numerically
                }
            } else if (aPart !== bPart) {
                return aPart.localeCompare(bPart); // Compare alphabetically or by special characters
            }
        }

        // If we reach here, the longer string is considered greater
        return aParts.length - bParts.length;
    };

    // Sort the data using the custom sort function
    return data.sort(sortFunction);
};

const downloadAsExcel = (
    tableRef,
    sheetName = "Sheet1",
    fileName = "download.xls"
) => {
    if (!tableRef || !tableRef.current) {
        console.error("Invalid table reference");
        return;
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    let rowIndex = 0;

    for (let row of tableRef.current.rows) {
        const rowData = [];
        for (let cell of row.cells) {
            const nestedTable = cell.querySelector("table");

            if (nestedTable) {
                // Format nested table data into a single cell
                let nestedData = "";
                for (let nestedRow of nestedTable.rows) {
                    for (let nestedCell of nestedRow.cells) {
                        nestedData += nestedCell.innerText + "\n"; // Append with line breaks
                    }
                }
                rowData.push(nestedData.trim()); // Add formatted nested data into the cell
            } else {
                rowData.push(cell.innerText); // Normal cells
            }
        }

        if (rowData.length > 0) {
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: rowIndex });
            rowIndex++;
        }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const workbookBinary = XLSX.write(workbook, {
        bookType: "xls",
        type: "binary",
    });
    const s2ab = (str) => {
        const buf = new ArrayBuffer(str.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < str.length; i++) view[i] = str.charCodeAt(i) & 0xff;
        return buf;
    };

    const buffer = s2ab(workbookBinary);
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};

const inputMaxLimit = (name, value, maxValue) => {
    const numericValue = value.trim() ? parseFloat(value) : 0;

    if (numericValue <= maxValue) {
        return { success: true, error: "" };
    } else {
        return {
            success: false,
            error: `Value must be less than or equal to ${maxValue}`,
        };
    }
};

const inputMinLimit = (name, value, minValue) => {
    const numericValue = value.trim() ? parseFloat(value) : 0;

    if (numericValue >= minValue) {
        return { success: true, error: "" };
    } else {
        return {
            success: false,
            error: `Value must be more than or equal to ${minValue}`,
        };
    }
};





export {
    calculateTotal,
    formatRoundoff,
    handleErrorToast,
    formatCurrencyIndian,
    formatRoundoffCrores,
    sortProjects,
    ascSortByKey,
    downloadAsExcel,
    inputMaxLimit,
    inputMinLimit,
    getMonthName,
  
};
