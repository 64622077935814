// ************ ==== >>>>Root Routes <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword";
import Resetpassword from "../pages/website/Resetpassword";

// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmpDashboard from "../pages/prebid/Main/Dashboard";
import Employeedashboard from "../pages/prebid/PrebidDashboard";

// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AccountDashboard from "../pages/account/Main/dashboard";
import AccountMainDashboard from "../pages/account/AccountDashboard";
import ClientsPage from "../pages/account/Main/clientDetails/ClientsPage";
import CriticalIssues from "../components/Criticalissue/CriticalIssue";
import { InvoicePage } from "../components/Project/Invoice";
import DueProject from "../components/Project/DueProject";

import Newprojectsdetails from "../components/Project/Newproject";
import Allclient from "../pages/account/Main/Allclient";
import Projectbyclient from "../pages/account/Main/Projectbyclient";

import AllProject from "../pages/account/Main/Allprojectdetails";
import AllProjectDetails from "../components/Project/Allprojectdetails";
import ProjectDetailsBilling from "../components/Project/ProjectDetailsBilling";
import PerformaInvoice from "../components/Project/PerformaInvoice";

export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },
      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },
    ],
  },

  // Employee Management
  {
    role: "Employee",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "ed/edDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "edDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },

          {
            path: "dueprojectdetails",
            component: <DueProject />,
          },
          {
            path: "clients",
            component: <ClientsPage />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
          {
            path: "ViewProjectDetails/:id/",
            component: <Newprojectsdetails />,
          },
        ],
        path: "ed",
        component: <Employeedashboard />,
      },
    ],
  },

  // Employee Management
  {
    role: "HOD",
    department: "Pre Engineering",
    isProtected: true,
    defaultRoute: "hod/edDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "edDashboard",
            component: <EmpDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "AllProjectDetails",
            component: <AllProjectDetails />,
          },
          {
            path: "dueprojectdetails",
            component: <DueProject />,
          },
          {
            path: "clients",
            component: <ClientsPage />,
          },
          {
            path: "newprojectdetails",
            component: <Newprojectsdetails />,
          },
        ],
        path: "hod",
        component: <Employeedashboard />,
      },
    ],
  },

  // Acccounts Management
  {
    role: "Employee",
    department: "Accounts",
    isProtected: true,
    defaultRoute: "account/accountDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <AccountDashboard />,
          },
          {
            path: "accountDashboard",
            component: <AccountDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "clients",
            component: <ClientsPage />, // Client Detail Page Not useful here
          },
          {
            path: "invoiceSlip/:id/",
            component: <InvoicePage />,
          },
          {
            path: "performainvoiceslip/:id/",
            component: <PerformaInvoice />,
          },
          {
            path: "allclient",
            component: <Allclient />, // Useful
          },
          {
            path: "projectbyclient/:id/",
            component: <Projectbyclient />,
          },
          {
            path: "invoicebyproject/:id/",
            component: <ProjectDetailsBilling />,
          },
          {
            path: "allprojectdetails/",
            component: <AllProject />,
          },
        ],
        path: "account",
        component: <AccountMainDashboard />,
      },
    ],
  },
];
