import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASE_URL_PREBID } from '../../config/axios';

const BalanceSummary = () => {
    const [statsDuration, setStatsDuration] = useState("2024");
    const [data, setData] = useState([]);

    useEffect(() => {


        const getnewclientdata = async () => {
            try {
                const res = await axios.get(`${BASE_URL_PREBID}/project/balance-summary-view/`);
                setData(res.data);

            } catch (err) {
                alert(err.message);
            }
        };
        getnewclientdata();
    }, [statsDuration]);

    return (
        <div className="balance-cont balance-chart-cont cont-col">
            <div className="dashboardcomponent-task">
                <div className="justify-between cont-row align-cen bgwhite mary-10">
                    <div>
                        <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">Balance Summary</div>
                    </div>
                    <div className="dashboard-priorities-select" >
                        <select
                            value={statsDuration}
                            onChange={(e) => setStatsDuration(e.target.value)}
                            className="dashboard-sectiona-selection"
                        >
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                        </select>
                        <div className="dashboard-priorities-select-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                        </div>
                    </div>
                </div>

                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                        <XAxis dataKey="name" tickMargin={10} />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top' height={50} margin={{ left: 100 }} />
                        <Line legendType="dot" type="monotone" dataKey="Pending" stroke="#FF747C" strokeWidth={5} Dot={10} />
                        <Line legendType="dot" type="monotone" dataKey="Received" stroke="#2576BC" strokeWidth={5} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div >
    );
};

export default BalanceSummary;
