import React, { useState } from "react";


const TransactionHistory = () => {

    const [statsduration, setStatsDuration] = useState("thismonth");


    const transactiondata = [
        {
            srno: "981",
            client: "Structure",
            projectname: "Social Science",
            date: "2024-03-10",
            amount: "900000",
            paymenthod: "Cash",
        }, {
            srno: "982",
            client: "Structure",
            projectname: "Social Science",
            date: "2024-03-10",
            amount: "900000",
            paymenthod: "Cash",
        }, {
            srno: "983",
            client: "Structure",
            projectname: "Social Science",
            date: "2024-03-10",
            amount: "900000",
            paymenthod: "Cash",
        }, {
            srno: "984",
            client: "Structure",
            projectname: "Social Science",
            date: "2024-03-10",
            amount: "900000",
            paymenthod: "Cash",
        },
    ]


    return (
        <div className="invoice-cont  width-40vw  cont-col cards-shadow2 bgwhite transaction-cont">
            <div className="justify-between cont-row align-cen bgwhite mary-10">
                <div>
                    <div className="repo-heading font-size-heading font-weight500card-heading font-sizeheading">Transaction History</div>
                </div>
                <div className="dashboard-priorities-select" >
                    <select
                        value={statsduration}
                        onChange={(e) => setStatsDuration(e.target.value)}
                        className="dashboard-sectiona-selection"
                    >
                        <option value="thismonth">This Month</option>
                        <option value="thisweek">This Week</option>
                        <option value="today">Today</option>
                        <option value="thisyear">This Year</option>
                        <option value="total">Overall</option>
                    </select>
                    <div className="dashboard-priorities-select-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className="global-table-cont">
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr global-table-tr">
                            <th className="align-left font-size-text font-weight400">Sr no.</th>
                            <th className="align-center font-size-text font-weight400">Client</th>
                            <th className="align-center font-size-text font-weight400">Project Name</th>
                            <th className="align-center font-size-text font-weight400">Date</th>
                            <th className="align-center font-size-text font-weight400">Amount</th>
                            <th className="align-center font-size-text font-weight400">Payment Method</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactiondata.map((items, index) => {
                            var currentDate = new Date(items.date);

                            var weekdays = [
                                "Sun",
                                "Mon",
                                "Tue",
                                "Wed",
                                "Thu",
                                "Fri",
                                "Sat",
                            ];
                            var months = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];

                            var currentWeekday = weekdays[currentDate.getDay()];
                            var currentDateOfMonth = currentDate.getDate();
                            var currentMonth = months[currentDate.getMonth()];

                            return (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="8"></td>
                                    </tr>
                                    <tr className="custom-table-head-td global-table-td">
                                        <td className="align-left font-size-text font-weight400">#{items.srno}</td>
                                        <td className="align-center font-size-text font-weight400">{items.client}</td>
                                        <td className="align-center font-size-text font-weight400">{items.projectname}</td>
                                        <td className="align-center font-size-text font-weight400">
                                            {currentWeekday + " " + currentDateOfMonth + ", " + currentMonth}
                                        </td>
                                        <td className="align-center font-size-text font-weight400">{items.amount}</td>
                                        <td className="align-center font-size-text font-weight400">{items.paymenthod}</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TransactionHistory